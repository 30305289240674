(function($) {
    $(function() {
        is_ie();
        /**
         * EU cookie policy
         */
        var cookie_policy = function() {
          if(!$.cookie('cookie_consent')){
            $('.js-cookie').addClass('show');
          }
          
          $('.js-cookie').on('closed.bs.alert', function() {
            $.cookie('cookie_consent', '1', { expires: 30, path: '/' });
          });
        }
        
        cookie_policy();
      
        /**
         * Braintree Drop-in UI
         * JavaScript v3 SDK
         * @url https://developers.braintreepayments.com/guides/drop-in/overview/javascript/v3
         */
        if($('.js-braintree').length) {
          
          // Init braintree payment form
          braintree.dropin.create({
            authorization: clientToken,
            container: '.js-payment-form',
            card: {
              cardholderName: { required: true },
              clearFieldsAfterTokenization: false
            }
          }, function(createErr, instance) {
            $('.js-submit-payment').on('click', function(e) {
              e.preventDefault();
              instance.requestPaymentMethod(function (requestPaymentMethodErr, payload) {
                if(requestPaymentMethodErr) { 
                  //console.error(requestPaymentMethodErr);
                  $('.js-braintree .js-nonce').attr('value', '');
                  return;
                }
                
                $('.js-braintree .js-nonce').val(payload.nonce);
                $('.js-braintree').trigger('wpcf7submit');
                $('.js-braintree').submit();
                
              });
            });
          });

        }
        
      
        /**
         * Toggle offcanvas
         */
        $('[data-toggle="offcanvas"]').on('click', function () {
          $('body').toggleClass('offcanvas-open');
        });
        
        /**
         * Swiper
         * @url http://idangero.us/swiper/api/
         */
        var swiper = new Swiper('.js-swiper', {
          loop: true,
          effect: 'fade',
          threshold: 10,
          fadeEffect: {
            crossFade: true
          },
          pagination: {
              el: '.swiper-pagination',
              type: 'bullets',
              clickable: true
          },
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
          },
        });
        

        /**
         * Hero swiper
         * @url http://idangero.us/swiper/api/
         */
        var swiper_hero = new Swiper('.js-swiper-hero', {
          loop: true,
          effect: 'fade',
          threshold: 10,
          fadeEffect: {
            crossFade: true
          },
          pagination: {
              el: '.swiper-pagination',
              type: 'bullets',
              clickable: true
          },
          navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
          },
        });
        
        /**
         * Init swiper
         *
         * @uses swiper.js
         */
        var swiper_logos = new Swiper('.js-swiper-logos', {
          loop: true,
          grabCursor: true,
          speed: 700,
          slidesPerView: 6,
          slidesPerGroup: 6,
          loopedSlides: 12,
          autoplayDisableOnInteraction: true,
          autoplay: {
            delay: 5000,
          },
          breakpoints: {
            767: {
              speed: 350,
          		slidesPerView: 4,
          		slidesPerGroup: 4,
            }
      	  }
        });
        
        
        /**
         * Range slider
         */
        $('input[type="range"]').rangeslider();
        
        
        /**
         * Tooltips
         */
        $('[data-toggle="tooltip"]').tooltip();
        
        
        /**
         * Animate scroll w/ anchors
         */
        $('a[href^="#"]:not([href="#"])').on('click', function(e) {
          e.preventDefault();
          
          if(location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash).length ? $(this.hash) : $('[name=' + this.hash.slice(1) +']');
            
            if(target.length) {
              var scroll_offset = target.offset().top;
              $('html,body').animate({
                scrollTop: scroll_offset
              }, 500);
            }
          }
        });
        
        
        /**
         * Calc & collapse helpers w/ scrolltop
         */
        function anim_scroll(target) {
          $('html,body').animate({
        	  scrollTop: ($(target).offset().top - 60)
          }, 350, 'swing');
        }
        
        // Only open calc, don't close
        $('.js-calc-collapse').on('hide.bs.collapse', function(e) {
          e.preventDefault();
          anim_scroll($('.js-calc-btn').data('target'));
        });
        
        $('.js-calc-collapse').on('shown.bs.collapse', function(e) {
          anim_scroll($('.js-calc-btn').data('target'));
        });
        
        
        /**
         * Modals w/ video
         */
        $('.js-modal-vid').on('show.bs.modal', function() { // Add src on show
          var vid = $(this).find('iframe');
          vid.attr('src', vid.data('src'));
        });
        
        $('.js-modal-vid').on('hidden.bs.modal', function() { // Remove src when closed
          $(this).find('iframe').removeAttr('src');
        });

        
        /**
         * [Partners] Potential score form
         */
        $('#potential-score .js-btn-submit').on('click', function(e) {
          e.preventDefault();
          
          var checked = $('#potential-score').find('input:checked').length;
          var total = parseInt($('#potential-score').find('.js-total').val());
          var score = Math.round(checked / total * 100);
          
          $('.js-results').show();
          $('.js-submit').hide();
          $('.js-score').html(score);
          
          $('.js-results-card').addClass('bg-primary').removeClass('bg-light');
          
        });
        
        // Clear partner score form
        $('#potential-score .js-btn-clear').on('click', function(e) {
          e.preventDefault();
          
          $('.js-results').hide();
          $('.js-submit').show();
          $('input:checkbox').prop('checked', false);
          $('.js-results-card').addClass('bg-light').removeClass('bg-primary');
          
          var target = $(this).data('target')
          anim_scroll(target);
        });


        /**
         * Check for IE!
         * @note Adds classes to `html` to target unsupported css
         */
        function is_ie() {
            var agent   = window.navigator.userAgent,
                browser = agent.indexOf("MSIE"),
                $html   = $('html');

            if(browser > 0){
                var ver = parseInt(agent.substring(browser + 5, agent.indexOf(".", browser)));
                $html.addClass('is_ie is_ie_' + ver);

                return false;
            } else if(!!navigator.userAgent.match(/Trident\/7\./)) {
                $html.addClass('is_ie is_ie_11');

                return false;
            } else if(/Edge\/\d./i.test(agent)) {
                $html.addClass('is_ie is_edge');

                return false;
            }

            return false;
        }
        
    });
})(window.jQuery);

